import React from 'react';
import { Header } from "../../../components/header";
import { SearchBar } from "../../../components/searchBar";
import { Sidebar } from "../../../components/sidebar";
import { Link } from "react-router-dom";
import "./pos.css";

export const PosDetails = () => {
    return (
        <div>
            <Header>
                <SearchBar />
            </Header>
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar/>
                    <div className="col-xl-6 col-xxl-7 main">
                            <div className="content-header">
                                <h1>POS Terminal</h1>
                                <div className="d-flex justify-content-between align-center">
                                    <span>Organizations - POS Terminal - Terminal name</span>
                                    <span className=" ms-4 d-flex justify-content-end align-items-center text-right">
                                        <span className=" me-3 ms-3 block-text">Easy POS</span>
                                        <label className="switch">
                                            <input type="checkbox" value="true"/>
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div className="main-content">
                                <div className="right-block">
                                    <div className="unverfied-customers-list">
                                        <div className="unverfied-customers-list-header d-flex justify-content-between align-center">
                                            <h3>POS Terminal information</h3>   
                                            <div className="single-user-edit d-flex justify-content-end align-items-center">
                                                <Link to="#"><span><i className="icon-edit"></i> Edit</span></Link>
                                            </div>
                                        </div>
                                        <ul className="single-customer-info p-0 mt-5 mb-5">
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Name</span>
                                                <span>POS Name</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Address</span>
                                                <span>Terminal address</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Phone Number</span>
                                                <span>+995595980469</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Commission</span>
                                                <span>POS commission</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Commision type</span>
                                                <span>POS Commision type</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="posDetails">
                                        <span className="d-flex justify-content-start mb-4 align-items-center text-right">
                                            <label className="switch">
                                                <input type="checkbox" value="true"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className=" me-3 ms-3 block-text">Multipay Payments</span>
                                        </span>
                                        <span className="d-flex justify-content-start align-items-center text-right">
                                            <label className="switch">
                                                <input type="checkbox" value="true"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <span className=" me-3 ms-3 block-text">Card and cash payment accounting</span>
                                        </span>
                                    </div>
                                    <div className="posMenu mt-5">
                                        <h5 className="posMenu-title">POS Terminal Menu</h5>
                                        <div className="uploadMneu mt-5">
                                            <button className="btn btn-outline-primary"><i className="icon-upload"></i> Update the menu</button>
                                        </div>
                                    </div>
                                    <div className="pos-footer d-flex justify-content-end mt-5">
                                        <button className="ms-auto btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}