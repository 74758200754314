import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Status401 from "../../helpers/helper";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Header } from "../../components/header";
import { Sidebar } from "../../components/sidebar";

import "./css/single.css";

export const SingleCustomer = () => {
    const history = useHistory();
    const [verifyTitle, setVerifyTitle] = useState("");
    const [customer, setCustomer] = useState([]);
    const { id } = useParams();
    useEffect(() =>{
        async function getSingleCustomer(){
            try{
                const singleCustomer = await axios.get(`http://testadmin.multiverse.ge/api/customer/v1/get-by-id?CustomerId=${id}`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    }
                });
                setCustomer(singleCustomer.data.data);
                if(singleCustomer.data.data.customerStatus === "Verified"){
                    setVerifyTitle('Unverify');
                } else {
                    setVerifyTitle('Verify');
                }
            } catch(error){
                if (error.response) {
                    Status401(error.response, history)
                }
            }
        }
        getSingleCustomer();
    }, [])
    
    async function verifyCustomer (inputValue){
        if(inputValue === 'Verified'){
            setVerifyTitle('Unverify');
        } else if (inputValue === 'UnVerified') {
            setVerifyTitle('Verify');
        }
        try{
            const verifyCustomer = await axios({
                method: 'put',
                url: 'http://testadmin.multiverse.ge/api/customer/v1/update-customer-status',
                headers: {
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                },
                data: {
                    customerId: customer.id,
                    customerStatus: inputValue,
                },
            });
            toast.success(verifyCustomer.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(function(){
                window.location.reload(false);
            }, 2300);
        } catch(error){
            if (error.response) {
                Status401(error.response, history)
            }
        }
    }

    return (
        <div>
            <ToastContainer/>
            <Header />
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                            <div className="content-header">
                                <h1>Customer</h1>
                                <div className="d-flex justify-content-between align-center">
                                    <span>{customer.fullName}</span>
                                    {
                                        customer.customerStatus === "Verified" ?
                                        <span className=" ms-5 d-flex justify-content-end align-items-center text-right">
                                        <span className="me-3 ms-3 block-text">{verifyTitle}</span>
                                        <label  className="switch">
                                            <input type="checkbox" defaultChecked onClick={() => verifyCustomer('UnVerified')} value='Verified'/>
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                    :
                                    <span className=" ms-5 d-flex justify-content-end align-items-center text-right">
                                        <span className="me-3 ms-3 block-text">{verifyTitle}</span>
                                        <label  className="switch">
                                            <input type="checkbox" onClick={() => verifyCustomer('Verified')} value='Verified'/>
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                    }
                                </div>
                            </div>
                            <div className="main-content">
                                <div className="right-block">
                                    <div className="unverfied-customers-list">
                                        <div className="unverfied-customers-list-header d-flex justify-content-between align-center">
                                            <div className="userAvatar d-flex justify-content-start align-items-center">
                                                <img width="60px" className="rounded-circle" src="https://icon2.cleanpng.com/20180319/row/kisspng-computer-icons-google-account-user-profile-iconfin-png-icons-download-profile-5ab0301d8907a6.3404305715214960935613.jpg" alt="avatar" />
                                                <span className="single-customer-name ms-3">{customer.name}</span>    
                                            </div>
                                            <div className="single-user-edit d-flex justify-content-end align-items-center">
                                                <Link to="/customer/create"><span><i className="icon-edit"></i> Edit</span></Link>
                                            </div>
                                        </div>
                                        <ul className="single-customer-info p-0 mt-5 mb-5">
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Full name</span>
                                                <span>{customer.fullName}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Gender</span>
                                                <span>{customer.gender}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Identificator</span>
                                                <span>{customer.identificator}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Date of birth</span>
                                                <span>{customer.birthDate}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Country of birth, city</span>
                                                <span>{customer.birthPlace}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Legal address</span>
                                                <span>{customer.legalAddress}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Actual address</span>
                                                <span>{customer.actualAddress}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Citizenship country code</span>
                                                <span>{customer.citizenshipCountryCode}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Identity document</span>
                                                <span>{customer.identityDoc}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Customer type</span>
                                                <span>{customer.customerType}</span>
                                            </li>
                                            <li className="single-customer-info-list d-flex justify-content-between">
                                                <span className="single-customer-info-list-name">Customer status</span>
                                                <span>{customer.customerStatus}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )

};