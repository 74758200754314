import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Status401 from "../../helpers/helper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Header } from "../../components/header";
import { SearchBar } from "../../components/searchBar";
import { Sidebar } from "../../components/sidebar";
import ChartImg from "../../assets/images/chart.png";
export const SingleOrganization = () => {
    const { id } = useParams();
    const history = useHistory();
    const [verifyTitle, setVerifyTitle] = useState("");

    const [singleOrganization, setSingleOrganization] = useState([]);

    useEffect(() =>{
        async function getSingleOrganization(){
            try{
                const singleOrganization = await axios.get(`http://testadmin.multiverse.ge/api/customer/v1/get-by-id?CustomerId=${id}`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    }
                });
                setSingleOrganization(singleOrganization.data.data);
                //console.log(singleOrganization.data.data);
                if(singleOrganization.data.data.customerStatus === "Verified"){
                    setVerifyTitle('Unverify');
                } else {
                    setVerifyTitle('Verify');
                }
            } catch(error){
                if (error.response) {
                    Status401(error.response, history)
                }
            }
        }
        getSingleOrganization();
    }, [])
    async function verifyOrganization (inputValue){
        if(inputValue === 'Verified'){
            setVerifyTitle('Unverify');
        } else if (inputValue === 'UnVerified') {
            setVerifyTitle('Verify');
        }
        try{
            const verifyOrganization = await axios({
                method: 'put',
                url: 'http://testadmin.multiverse.ge/api/customer/v1/update-customer-status',
                headers: {
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                },
                data: {
                    customerId: singleOrganization.id,
                    customerStatus: inputValue,
                },
            });
            toast.success(verifyOrganization.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(function(){
                window.location.reload(false);
            }, 2300);
        } catch(error){
            if (error.response) {
                Status401(error.response, history)
            }
        }
    }
    return (
        <div>
            <ToastContainer/>
            <Header>
                <SearchBar/>
            </Header>
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header">
                            <h1>Organization</h1>
                            <div className="content-middle d-flex justify-content-between mb-5">
                                <span className="mr-auto">Organizations - {singleOrganization.legalName}</span>
                                <div className="d-flex">
                                    <span className="addPos me-4">
                                        <a href={'/organizations/create/pos/'+singleOrganization.id}>
                                            <i className="icon-user-plus"></i> Add Pos
                                        </a>
                                    </span>
                                    {
                                        singleOrganization.customerStatus === "Verified" ?
                                        <span className=" ms-5 d-flex justify-content-end align-items-center text-right">
                                        <span className="me-3 ms-3 block-text">{verifyTitle}</span>
                                        <label  className="switch">
                                            <input type="checkbox" defaultChecked onClick={() => verifyOrganization('UnVerified')} value='Verified'/>
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                    :
                                    <span className=" ms-5 d-flex justify-content-end align-items-center text-right">
                                        <span className="me-3 ms-3 block-text">{verifyTitle}</span>
                                        <label  className="switch">
                                            <input type="checkbox" onClick={() => verifyOrganization('Verified')} value='Verified'/>
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                    } 
                                </div>
                            </div>
                        </div>
                        <div className="main-content row">
                            <div className="col-lg-5">
                                <div className="block">
                                    <div className="block-content">
                                        <div className="user-info">
                                            <div className="profile-image d-flex align-items-center">
                                                <div className="avatar-text bg-primary">
                                                    <span>{singleOrganization.legalName && singleOrganization.legalName.charAt(0) || singleOrganization.fullName && singleOrganization.fullName.charAt(0)}</span>
                                                </div>
                                                <span className="user-name ms-4">{singleOrganization.legalName} ({singleOrganization.customerStatus})</span>
                                            </div>
                                            <ul className="user-details list-unstyled mt-4">
                                                <li className="d-flex"><i className="icon-phone"></i><span>+995 55 55 55</span></li>
                                                <li className="d-flex"><i className="icon-mail"></i><span>{singleOrganization.identificator}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="block">
                                    <div className="block-header d-flex justify-content-between">
                                        <h3 className="d-inline-flex">KYC</h3>
                                        <span><a className="block-link" href="!#"><i className="icon-eye"></i> See all</a></span>
                                    </div>
                                    <div className="block-content">
                                        <ul className="user-work">
                                            <li>User employer<span>Boomerang LTD</span></li>
                                            <li>Occupied position<span>UI - UX Designer</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="block">
                                    <div className="block-header d-flex justify-content-between">
                                        <h3 className="d-inline-flex">Accounts</h3>
                                        <div className="block-title-right">
                                            <span><a className="block-link" href="!#"><i className="icon-wallet"></i> Add Account</a></span>
                                        </div>
                                    </div>
                                    <div className="block-content d-flex">
                                        <div className="col-12 col-md-8 pr-0 pl-0">
                                            <table className="table accounts-table">
                                                <thead>
                                                    <tr>
                                                        <th>Multiverse address</th>
                                                        <th>Type</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>123456789101112131431</td>
                                                        <td>Card</td>
                                                        <td>1320 GEL</td>
                                                    </tr>
                                                    <tr>
                                                        <td>123456789101112131431</td>
                                                        <td>Other</td>
                                                        <td>200 USD</td>
                                                    </tr>
                                                    <tr>
                                                        <td>123456789101112131431</td>
                                                        <td>Card</td>
                                                        <td>0 EUR</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <img src={ChartImg} width="280" alt="Chart" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="block">
                                    <div className="block-header d-flex justify-content-between">
                                        <h3 className="d-inline-flex">Transactions</h3>
                                        <div className="block-title-right">
                                            <span><a className="block-link" href="!#"><i className="icon-eye"></i> See all</a></span>
                                        </div>
                                    </div>
                                    <div className="block-content d-flex">
                                        <div className="col-12 pr-0 pl-0">
                                            <table className="table accounts-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Data</th>
                                                        <th>Description</th>
                                                        <th>Account/Card</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><i className="chart-low icon-arrow_forward_icon"></i></td>
                                                        <td>18 May 2021</td>
                                                        <td>POS - Space, card MC, Lorem ipsum transan...</td>
                                                        <td>GE47...0001</td>
                                                        <td>320.00 <span className="currency">GEL</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="chart-high icon-arrow_forward_icon"></i></td>
                                                        <td>17 May 2021</td>
                                                        <td>POS - Space, card MC, Lorem ipsum transan...</td>
                                                        <td>GE47...0001</td>
                                                        <td>2400.00 <span className="currency">USD</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><i className="chart-low icon-arrow_forward_icon"></i></td>
                                                        <td>17 May 2021</td>
                                                        <td>POS - Space, card MC, Lorem ipsum transan...</td>
                                                        <td>GE47...0001</td>
                                                        <td>-150.00 <span className="currency">EUR</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 mt-xl-0 col-xl-3 col-xxl-3">
                    <div className="right-sidebar">
                        <div className="right-side-block">
                            <h3 className="block-title d-flex justify-content-between">
                                <span>Personal information</span>
                                <a href="!#"><i className="icon-edit"></i> Edit</a>
                            </h3>
                            <div className="block-content">
                                <ul className="personal-info">
                                    <li className="personal-info-item">Gender<span className="personal-info-desc">Male</span></li>
                                    <li className="personal-info-item">Country of birth, city<span className="personal-info-desc">Georgia, Tbilisi</span></li>
                                    <li className="personal-info-item">Date of birth<span className="personal-info-desc">01/04/1998</span></li>
                                    <li className="personal-info-item">Country code<span className="personal-info-desc">Georgia, 0161</span></li>
                                    <li className="personal-info-item">Legal address<span className="personal-info-desc">Tbilisi,  Vaja Pshavela 43</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="right-side-block">
                            <h3 className="block-title d-flex justify-content-between">
                                <span>Identity document</span>
                                <a href="!#"><i className="icon-edit"></i> Edit</a>
                            </h3>
                            <div className="block-content">
                                <ul className="personal-info">
                                    <li className="personal-info-item">Personal number<span className="personal-info-desc">12345678910</span></li>
                                    <li className="personal-info-item">Issuing authority<span className="personal-info-desc">Public service hall</span></li>
                                    <li className="personal-info-item">Date of issue<span className="personal-info-desc">01/04/2022</span></li>
                                    <li className="personal-info-item">Validity period<span className="personal-info-desc">01/04/2027</span></li>
                                    <li className="personal-info-item">Document number<span className="personal-info-desc">12IB34567</span></li>
                                    <li className="personal-info-item">Issuing country<span className="personal-info-desc">Georgia</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="right-side-block-transparent">
                            <div className="block-content d-flex justify-content-between align-items-center pr-5 pl-5">
                                <span className="risk-level"><i className="icon-user_icon"></i> User risk level</span>
                                <span className="risk-status mt-2">Low risk</span>
                                <a href="!#" className="btn btn-outline-primary">See All</a>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}