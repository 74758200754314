import React, { useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Status401 from "../../../helpers/helper";
import { ToastContainer, toast } from "react-toastify";
import { Header } from "../../../components/header";
import { SearchBar } from "../../../components/searchBar";
import { Sidebar } from "../../../components/sidebar";

export const CreatePos = () => {
    const { id } = useParams();
    const history = useHistory();
    const [selectedFile, setSelectedFile] = React.useState(null);
var formData = new FormData();
    const [posName, setPosName]=useState("");
    const [address, setAddress]=useState("");
    const [number, setNumber]=useState("");
    const [countryCode, setCountryCode]=useState("");
    const [commision, setCommission]=useState("");
    const [commisionType, setCommissionType]=useState("");
    const [productFile, setProductFile]=useState("");
    async function CreatePosTerminal(e) {
        e.preventDefault();
        if(posName === "" || address === "" || commisionType === ""){
            toast.warn('All fields required!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
        } else {
            const formData = new FormData();
            formData.append("ProductFile", selectedFile);
            formData.append("CustomerId", id);
            formData.append("PosName", posName);
            formData.append("CommissionFee.Amount", commision);
            formData.append("CommissionFee.FeeType", commisionType);
            formData.append("MobileNumber.Number", number);
            formData.append("MobileNumber.CountryCode", countryCode);
            try {
                const genratePos = await axios({
                    url: 'http://testadmin.multiverse.ge/v1/generate-pos',
                method: 'POST',
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                },
                data: formData
                });
                toast.success('POS Registered Succesfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
            } catch(error){
                if (error.response) {
                    Status401(error.response, history)
                }
            }
        }

    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
      }
    return (
        <div>
            <ToastContainer />
            <Header>
                <SearchBar />
            </Header>
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar/>
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header mb-5">
                            <h1>Create new POS Terminal</h1>
                            <span className="mr-auto">Organizations - Create new POS Terminal</span>
                        </div>
                        <div className="main-content row">
                            <div className="col-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h3 className="mb-5">POS Terminal information</h3>
                                        <form onSubmit={CreatePosTerminal} encType="multipart/form-data">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" placeholder="posName" onChange={(e)=>setPosName(e.target.value)} className="form-control" id="posName" />
                                                        <label>Pos name</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" placeholder="address" onChange={(e)=>setAddress(e.target.value)} className="form-control" id="address"/>
                                                        <label>Address</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating">
                                                        <select className="form-select" id="phoneCountryCode" onChange={(e)=>setCountryCode(e.target.value)} aria-label="phoneCountryCode">
                                                            <option defaultValue ="">Select</option>
                                                            <option value="995">+995</option>
                                                        </select>
                                                        <label>Country Code</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-3">
                                                        <input type="tel" placeholder="Phone number" onChange={(e)=>setNumber(e.target.value)} className="form-control" id="phoneNumber" />
                                                        <label>Phone Number</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating">
                                                        <select className="form-select" id="customerType" onChange={(e)=>setCommissionType(e.target.value)} aria-label="customerType">
                                                            <option defaultValue="">Commission Type</option>
                                                            <option value="Fixed">Fixed</option>
                                                            <option value="Percents">Percents</option> 
                                                        </select>
                                                        <label>Commission Type</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" placeholder="commision" onChange={(e)=>setCommission(e.target.value)} className="form-control" id="commision"/>
                                                        <label>Commission</label>
                                                    </div>
                                                </div>

                                                <div className="col-12 text-end">
                                                <label className="btn btn-outline-primary me-4">
                                                <i className="icon-upload"></i> Upload File <input onChange={handleFileSelect} type="file" name="fileName" className="" accept=".csv,.xlsx,.xls"/>
                                                </label>
                                                    <button type="submit" className="btn btn-primary"> Generate POS <i className="icon-arrow"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}