import React  from 'react';
import { useDispatch } from "react-redux";
import { getLogout } from "../redux/actions";
import { SearchBar } from "./searchBar";
import Logo from '../assets/images/multiverse_logo.svg';
import Avatar from '../assets/images/avatar.png';

export const Header = () => {
  const dispatch = useDispatch();
  return (
    <header>
        <div className="header">
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid pt-3 pb-3">
                    <a className="navbar-brand ps-5 pe-5" href="/customers">
                        <img src={Logo} width="150" alt="MultiPay" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <SearchBar />
                        <ul className="navbar-nav ms-auto profile-menu"> 
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="icon-notification_bell_icon profile-dropdown-arrow"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-start-me" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="!#"><i className="fas fa-sliders-h fa-fw"></i> Account</a></li>
                                    <li><a className="dropdown-item" href="!#"><i className="fas fa-cog fa-fw"></i> Settings</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="!#"><i className="fas fa-sign-out-alt fa-fw"></i> Log Out</a></li>
                                </ul>
                            </li> */}

                            <li className="nav-item dropdown ms-5">
                                <span className="nav-link dropdown-toggle d-flex align-items-center" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className="img-fluid rounded-circle" src={Avatar} width="45"  alt="avatar"/><i className="ps-4 profile-dropdown-arrow icon-arrow-chevron"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-start-me" aria-labelledby="navbarDropdown">
                                    <li className="cursor-pointer" onClick={() => dispatch(getLogout())}><span className="dropdown-item"> Log Out</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </header>
  );
};