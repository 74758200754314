import React from 'react';
import { Header } from "../../components/header"
import { SearchBar } from "../../components/searchBar";
import { Sidebar } from "../../components/sidebar";
import { OrganizationsList } from './organizationsList';
import { Link } from 'react-router-dom';
import "./organizations.css"
export const Organizations = () => {
    return (
        <div>
        <Header>
            <SearchBar />
        </Header>
        <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header">
                            <h1>Organizations</h1>
                            <div className="d-flex justify-content-between align-center">
                                <span>Whole data about your business here</span>
                                <div className="options-group">
                                    <Link to="/organizations/create"><span className='addCustomer'><i className="icon-user-plus"></i> Add organization</span></Link>
                                    {/* <a>Filter</a> */}
                                </div>

                            </div>
                        </div>
                        <div className="main-content">
                            <div className="right-block">
                                <div className="unverfied-customers-list">
                                    <div className="unverfied-customers-list-header d-flex justify-content-between align-items-center align-center">
                                        <h4 className="col-6">Organizations</h4>
                                        <span className="col-3">Identification code</span>
                                        <span className="col-3">Phone Number</span>
                                    </div>
                                    <ul className="unverfied-customers-list-items">
                                        <OrganizationsList />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
