import React, { useState } from "react";
import  Logo  from '../assets/images/multiverse_logo.svg';
import  LoginBg from '../assets/images/login_image.svg';
import { useDispatch } from "react-redux";
import { getLogin } from "../redux/actions";
import { useHistory } from "react-router-dom";
import "./login/login.css";

export const Login = () => {
    const history = useHistory();
    const userToken = localStorage.getItem('userToken');
    if(userToken){
        history.push("/customers");
    }
    const dispatch = useDispatch();
    const [username, setUsername]=useState("");
    const [password, setPassword]=useState("");
    const grant_type = "ADMIN_GRANT";
    const scope = "AdminAPI";
    const client_id = "admin-web";
    const client_secret = "admin";

  const signIn = (e) => {
    e.preventDefault();
    if (username && password) {
        dispatch(getLogin());
        fetchToken();
    } else {
      alert("Wrong Credentials");
    }
  };

  async function fetchToken(){
    let credentials = {grant_type, scope, client_id, client_secret, username, password};
    let result = await fetch("http://testidp4.multiverse.ge/connect/token",{
        method: "POST",
        header:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        },
        body: new URLSearchParams(credentials),
    });
    result = await result.json();
    console.log(result);
    if(result.access_token && result.scope){
        localStorage.setItem('userToken', result.access_token);
        history.push("/customers");
    } else {
        //alert(result.error_description);
    }

}

  return (

<div className="container-fluid">
        <div className="row">
            <div className="login-images col-lg-7 pt-5 pb-5 vh-100 d-none d-lg-block">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="mx-auto">
                        <div className="login-bg">
                            <img src={LoginBg} alt="" />
                        </div>
                        <div className="multiverse-logo mt-5">
                            <img src={Logo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-5 pt-5 pb-5 vh-100">
                <div className="d-flex align-items-center justify-content-center w-100 h-100 p-0">
                    <div className="login-form-bg h-100 align-items-center justify-content-center d-flex">
                        <div className="mx-auto login-form">
                            <h1 className="login-title">Hi There!</h1>
                            <form onSubmit={signIn}>
                                <div className="form-group mb-5">
                                    <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} className="login-inputs" placeholder="Username"/>
                                </div>
                                <div className="form-group">
                                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="login-inputs" placeholder="Password" />
                                </div>
                                {/* <a className="password-lost text-center" href="!#">Problems with login?</a> */}
                                <div className="form-goup mt-5">
                                    <button type="submit" className="btn login-button">Login <i className="icon-arrow"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
