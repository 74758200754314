import { Header } from "../../components/header";
import { Sidebar } from "../../components/sidebar";
import Status401 from "../../helpers/helper";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";


export const CustomerCreate = () => {
    const history = useHistory();

    const [flowId, setFlowId] = useState("");
    const [OTPCode, setOTPCode] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [firstName, setFirtsName]=useState("");
    const [lastName, setLastName]=useState("");
    const [email, setEmail]=useState("");
    const [personalId, setPersonalId]=useState("");
    const [countryCode, setCountryCode]=useState("");
    const [phone, setPhone]=useState("");

    async function createCustomer(e) {
        e.preventDefault();
        if(!firstName || !lastName || !personalId || !email || !countryCode || !phone){
            toast.warn('All fields required!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
        } else {
            try {
                const newCustomer = await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/1', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    },
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        customerType: 'Individual',
                        email: email,
                        identificator: personalId,
                        mobileNumber : {
                            countryCode:countryCode,
                            number: phone
                        }
                    },
                });
                setFlowId(newCustomer.data.data.flowId);
                setShow('true');
                toast.success('OTP code sent succesfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
            } catch(error){
                console.log(error);
            }
        }
    }

    async function resendOTP() {
        try {
            const resendCode = await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/1/resend-otp', {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                },
                data: {
                    flowId: flowId,
                },
            });
            setFlowId(resendCode.data.data.flowId);
            if(flowId){
                toast.success('OTP code sent succesfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
            console.log(flowId);
        } catch(error){
            console.log(error);
            if (error.response) {
                Status401(error.response, history);
            }
        }
    }

    async function verifyOTP() {
        if(!OTPCode){
            toast.warning("OTP code can't be empty!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
        } else {
            try {
                const finishRegistration = await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/2', {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    },
                    data: {
                        flowId: flowId,
                        otpCode: OTPCode,
                    },
                });
                setShow(false);
                toast.success('Customer registered successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(function(){ window.location="/customers"; },2500);
            } catch(error){
                console.log('Something is Wrong');
            }
        }
    }

    return (
        <div>
            <ToastContainer />
            <Header />
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header mb-5">
                            <h1>Create new user</h1>
                                <span className="mr-auto">Customers - Create new user</span>
                        </div>
                        <div className="main-content row">
                            <div className="col-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h3>User information</h3>
                                        <form onSubmit={createCustomer} className="">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-4">
                                                        <input type="text" placeholder="firstName" className="form-control" onChange={(e)=>setFirtsName(e.target.value)} id="firstName" />
                                                        <label>First name</label>
                                                        </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-4">
                                                        <input type="text" placeholder="lastName" className="form-control" onChange={(e)=>setLastName(e.target.value)} id="lastName"/>
                                                        <label>Last name</label>
                                                        </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-4">
                                                        <input type="number" placeholder="Personal ID" className="form-control" onChange={(e)=>setPersonalId(e.target.value)} id="Personal ID"/>
                                                        <label>Personal ID</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-4">
                                                        <input type="email" placeholder="Email" className="form-control" onChange={(e)=>setEmail(e.target.value)} id="email"/>
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating">
                                                        <select className="form-select" id="phoneCountryCode" onChange={(e)=>setCountryCode(e.target.value)} aria-label="phoneCountryCode">
                                                            <option defaultValue ="">Select</option>
                                                            <option value="995">+995</option>
                                                        </select>
                                                        <label>Country Code</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-floating mb-4">
                                                        <input type="tel" placeholder="Phone number" className="form-control" onChange={(e)=>setPhone(e.target.value)} id="phoneNumber" />
                                                        <label>Phone Number</label>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-12 text-end">
                                                    <button type="submit" className="btn btn-lg btn-outline-primary" data-bs-toggle="modal" data-bs-target="#EnterCode">Send Code</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show}  backdrop="static" keyboard="false" onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Verify OTP code!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="form-floating mb-4">
                            <input type="tel" className="form-control" onChange={(e) => setOTPCode(e.target.value)} placeholder="Enter Code" id="otpCode"/>
                            <label>OTP Code</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="button" onClick={resendOTP}  className="btn btn-outline-primary">Resend Code</button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={verifyOTP}>Verify OTP</button>
            </Modal.Footer>
            </Modal>
        </div>
    )
}