import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Status401 from '../../helpers/helper';
export const CustomerItem = () => {
    let history = useHistory();
    const [verifiedCustomers, setVerifiedCustomers] = useState([]);

    useEffect(() =>{
        async function getCustomers(){
            try{
            const getVerifiedCustomers = await axios.get('http://testadmin.multiverse.ge/api/customer/v1/search-customers?SearchVerified=true&Page=1&RecordsOnPage=10', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                }
            });
            setVerifiedCustomers(getVerifiedCustomers.data.data.records);
            } catch(error){
                if (error.response) {
                    Status401(error.response, history);
                }
            }
        }
        getCustomers();
    },[]);


    return (
        verifiedCustomers.map((verifiedCustomer, key) => {
            if(verifiedCustomer.customerType === 'Individual'){
                return (
                    <a key={key} className="text-decoration-none customers-hover" href={'/customers/'+verifiedCustomer.id}>
                        <li className="unverfied-customers-list-item d-flex justify-content-between align-center">
                            <div className="customer-info d-flex justify-content-start align-center col-3">
                                <div className="avatar-text bg-primary">
                                    <span className="text-uppercase">{verifiedCustomer.fullName.charAt(0)}</span>
                                </div>
                                <div className="customer-name">
                                    <h6>{verifiedCustomer.fullName}</h6>
                                    <span className="cusomer-status">Online</span>
                                </div>
                            </div>
                            <div className="cusomer-phone-number col-3">
                                {verifiedCustomer.email}
                            </div>
                            <div className="cusomer-phone-number col-2">
                                <span className="country-code">+ {verifiedCustomer.mobileNumber.countryCode}</span> {verifiedCustomer.mobileNumber.number}
                            </div>
                            <div className="cusomer-phone-number col-2">
                            {verifiedCustomer.customerType === 'LegalEntity' ? 'Legal entity' : verifiedCustomer.customerType}
                            </div>
                            <div className="cusomer-phone-number col-2">
                                {verifiedCustomer.identificator}
                            </div>
                        </li>
                    </a>
                )
            }
        })
    )
} 
