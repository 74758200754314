import React from 'react';
import { Header } from "../components/header";
import { Sidebar } from "../components/sidebar";
export const Dashboard = () => {
  return (
    <section>
      <Header />
      <div className="container-fluid pt-5 pb-5">
          <div className="row">
            <Sidebar />
            <div className="col-md-6">
              <h1>Home Page</h1>
            </div>
          </div>
      </div>
    </section>
  );
};
