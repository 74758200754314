import React from 'react';
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
    return (
          <aside className="d-none d-xl-block col-xl-3 col-xxl-2">
              <div className="left-sidebar">
                  <ul className="left-navigation">
                      {/* <li> <NavLink to="/"><i className="icon-dashboard"></i><span>Dashboard</span></ NavLink></li> */}
                      <li> <NavLink to="/customers"><i className="icon-user"></i><span>Customers</span></ NavLink></li>
                      <li> <NavLink to="/organizations"><i className="icon-briefcase"></i><span>Organizations</span></ NavLink></li>
                      {/* <li><a href="!#"><i className="icon-users"></i><span>Team</span></a></li>
                      <li><a href="!#"><i className="icon-reports"></i>Reports<strong className="ml-auto">99+</strong></a></li> */}
                  </ul>
              </div>
          </aside>
    )
  };