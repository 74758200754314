import "./assets/css/style.css";
import "./assets/fonts/fonts.css";
import './assets/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import { Login } from "./container/login";
import { Dashboard } from "./container/dashboard";
import Customers  from "./container/customers/cutomers";
import { SingleCustomer } from "./container/customers/singleCustomer";
import { CustomerCreate } from "./container/customers/createCusomer";
import { Organizations } from "./container/organizations/organizations";
import { OrganizationCreate } from "./container/organizations/organizationCreate";
import { SingleOrganization } from "./container/organizations/singleOrganization";
import { CreatePos } from "./container/organizations/pos/createPos";
import { PosDetails } from "./container/organizations/pos/posDetails";
import { PageNotFound } from "./container/pageNotFound";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import { useSelector } from "react-redux";



export default function App() {
  const auth = useSelector((state) => state.isAuthenticated);
  return (
    <Router>
      <Switch>
        <ProtectedRoute auth={auth} exact path="/" element={ <Redirect to="/customers" /> } component={Dashboard} />
        <ProtectedRoute auth={auth} exact path="/customers" component={Customers} />
        <ProtectedRoute auth={auth} exact path="/customers/create" component={CustomerCreate} />
        <ProtectedRoute auth={auth} exact path="/customers/:id" component={SingleCustomer} />
        <ProtectedRoute auth={auth} exact path="/organizations" component={Organizations} />
        <ProtectedRoute auth={auth} exact path="/organizations/create" component={OrganizationCreate} />
        <ProtectedRoute auth={auth} exact path="/organizations/create/pos/:id" component={CreatePos} />
        <ProtectedRoute auth={auth} exact path="/pos/details/:id" component={PosDetails} />
        <ProtectedRoute auth={auth} exact path="/organizations/:id" component={SingleOrganization} />


        <Route exact path="/login" component={Login} />
        <ProtectedRoute auth={auth} path="/*" component={PageNotFound}/>
      </Switch>
    </Router>
  );
}
