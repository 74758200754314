import React, { useState } from "react";
import Modal  from "react-bootstrap/Modal";
import { Header } from "../../components/header";
import { SearchBar } from "../../components/searchBar";
import { Sidebar } from "../../components/sidebar";
import axios from "axios";
import Status401 from "../../helpers/helper";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const OrganizationCreate = () => {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [flowId, setFlowId] = useState("");
    const [OTPCode, setOTPCode] = useState("");
    const [organizationName, setOrganizationName]=useState("");
    const [organizationId, setOrganizationId]=useState("");
    const [email, setEmail]=useState("");
    const [countryCode, setCountryCode]=useState("");
    const [phone, setPhone]=useState("");
    async function CreateOrganization(e) {
        e.preventDefault();
        if(!organizationName || !organizationId || !countryCode || !phone){
            toast.warn('All fields required!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
        } else {
            try {
                const newCustomer = await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/1', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    },
                    data: {
                        legalName: organizationName,
                        identificator: organizationId,
                        customerType: 'LegalEntity',
                        email: email,
                        mobileNumber : {
                            countryCode:countryCode,
                            number: phone,
                        }
                    },
                });
                setFlowId(newCustomer.data.data.flowId);
                toast.success('OTP code sent succesfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
                setShow(true);
            } catch(error){
                console.log(error);
            }
        }
    }

    async function resendOTP() {
        try {
            const resendCode = await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/1/resend-otp', {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                },
                data: {
                    flowId: flowId,
                },
            });
            setFlowId(resendCode.data.data.flowId);
            if(flowId){
                toast.success('OTP code sent succesfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
            console.log(flowId);
        } catch(error){
            console.log(error);
            if (error.response) {
                Status401(error.response, history);
            }
        }
    }

    async function verifyOTP() {
        if(!OTPCode){
            toast.warning("OTP code can't be empty!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                pauseOnFocusLoss: false,
                progress: undefined,
                theme: "colored",
            });
        } else {
            try {
                await axios('http://testadmin.multiverse.ge/api/customer/v1/flows/create-customer/steps/2', {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    },
                    data: {
                        flowId: flowId,
                        otpCode: OTPCode,
                    },
                });
                setShow(false);
                toast.success('Organisation registered successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    pauseOnFocusLoss: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(function(){ window.location="/organizations"; },2500);
            } catch(error){
                console.log('Something is Wrong');
            }
        }
    }
    return ( 
        <div>
            <ToastContainer />
            <Header>
                <SearchBar/>
            </Header>
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header">
                            <h1>Create new organization user</h1>
                            <div className="d-flex justify-content-between align-center">
                                <span>Customers - Create new organization user</span>

                            </div>
                        </div>
                        <div className="main-content">
                            <div className="right-block">
                                <form onSubmit={CreateOrganization}>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-xl-3">
                                        <div className="form-floating mb-3">
                                            <input type="text" autoFocus className="form-control" onChange={(e)=>setOrganizationName(e.target.value)} placeholder="Name of Organization"  name="organizationName" id="organizationName" />
                                            <label for="organizationName">Name of Organization</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xl-3">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" onChange={(e)=>setOrganizationId(e.target.value)} name="IdentificationCode" placeholder="Identification code" id="identificationCode"/>
                                            <label for="IdentificationCode">Identification code</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xl-3">
                                        <div className="form-floating">
                                            <select className="form-select" id="phoneCountryCode" onChange={(e)=>setCountryCode(e.target.value)} aria-label="phoneCountryCode">
                                                <option defaultValue ="">Select</option>
                                                <option value="995">+995</option>
                                            </select>
                                            <label>Country Code</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xl-3">
                                        <div className="form-floating mb-4">
                                            <input type="tel" placeholder="Phone number" className="form-control" onChange={(e)=>setPhone(e.target.value)} id="phoneNumber" />
                                            <label>Phone Number</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-xl-3">
                                        <div className="form-floating mb-4">
                                            <input type="email" placeholder="Email" className="form-control" onChange={(e)=>setEmail(e.target.value)} id="email" />
                                            <label>Email</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end mt-5">
                                        <button type="file" className="btn btn-outline-primary me-4"><i className="icon-upload"></i> Upload Document</button>
                                        <button type="submit" className="btn btn-primary">Next</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show}  backdrop="static" keyboard="false" onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Verify OTP code!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="form-floating mb-4">
                            <input type="tel" className="form-control" onChange={(e) => setOTPCode(e.target.value)} placeholder="Enter Code" id="otpCode"/>
                            <label>OTP Code</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="button" onClick={resendOTP}  className="btn btn-outline-primary">Resend Code</button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={verifyOTP}>Verify OTP</button>
            </Modal.Footer>
            </Modal>
        </div>
    );

}
 