import React, { useEffect, useState } from "react";
import Status401 from "../helpers/helper";
import { useHistory } from 'react-router-dom';
import axios from "axios";
export const SidebarRight = () => {
    const history = useHistory();
    const [unverifiedCustomers, setUnverifiedCustomers] = useState([]);
    const [ postNum, setPostNum] = useState(10);
    useEffect(() =>{
        async function getCustomers(RecordsOnPage, Page){
            try{
                const unverifiedCustomers = await axios.get(`http://testadmin.multiverse.ge/api/customer/v1/search-customers?SearchVerified=false`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                    }
                });
                setUnverifiedCustomers(unverifiedCustomers.data.data.records);
                } catch(error){
                    if (error.response) {
                        Status401(error.response, history)
                    }
            }
        }
        getCustomers();
    },[]);
    function loadUnverifiedCustomers() {
        setPostNum(prevPostNum => prevPostNum + 5)
    }
    
        return (
            <aside className="mt-5 mt-xl-0 col-xl-3 col-xxl-3">
                <div className="right-sidebar h-100">
                    <div className="right-block">
                        <div className="unverfied-customers-list">
                            <div className="unverfied-customers-list-header d-flex justify-content-between align-center">
                                <h4>Unverified</h4>
                                <span>Phone number</span>
                            </div>
                            <ul className="unverfied-customers-list-items">
                            {
                                unverifiedCustomers.slice(0, postNum).map((unverifiedCustomer, i) => {
                                    if(unverifiedCustomer.customerType === 'Individual'){
                                        return(
                                            <a key={i} className="text-decoration-none customers-hover" href={`/customers/${unverifiedCustomer.id}`}>
                                                <li className="unverfied-customers-list-item d-flex justify-content-between align-center">
                                                    <div className="customer-info d-flex justify-content-between align-center">
                                                        {/* <img src="images/avatar.png" className="rounded-circle" alt="Lisha Cook" /> */}
                                                        <div className="customer-name">
                                                            <h6>{unverifiedCustomer.fullName}</h6>
                                                            <span className="cusomer-status">{unverifiedCustomer.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className="cusomer-phone-number">
                                                        <span className="country-code">{unverifiedCustomer.mobileNumber.countryCode}</span> {unverifiedCustomer.mobileNumber.number}
                                                    </div>
                                                </li>
                                            </a>
                                        )
                                    }
                                })
                            } 
                            </ul>
                            <div className="loadMore d-flex justify-content-center mt-4 mb-3">
                            <button onClick={loadUnverifiedCustomers} className="btn brn-sm btn-outline-primary cursor-pointer">
                              Load More
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        )
  };