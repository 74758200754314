import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./constants";

const initialState = {
  isAuthenticated: localStorage.getItem("userToken") || false,
  user: { email: "Admin", pass: "Admin" }
};

// Reducers
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };

    case LOGOUT_SUCCESS:
      localStorage.removeItem("userToken");
      return {
        ...state,
        isAuthenticated: false
      };

    default:
      return state;
  }
};

export default AuthReducer;
