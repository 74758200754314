import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Status401 from "../helpers/helper";
import { useHistory } from 'react-router-dom';

import "./serachbar.css";

export const SearchBar = () => {
    let history = useHistory();

    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [customers, setCustomers] = useState([]);
    useEffect(() =>{
        async function getCustomers(){
            try{
            const getCustomers = await axios.get('http://testadmin.multiverse.ge/api/customer/v1/search-customers', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                }
            });
            setCustomers(getCustomers.data.data.records);
            
            } catch(error){
                if (error.response) {
                    Status401(error.response, history);
                }
            }
        }
        getCustomers();
    },[]);

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setSearchTerm(searchWord);
        const newFilter = customers.filter((query) => {

            //if(query.fullName.toLowerCase().includes(searchWord.toLowerCase())){
                return query.fullName && query.fullName.toLowerCase && query.fullName.toLowerCase().includes(searchTerm.toLocaleLowerCase()) || 
                query.identificator && query.identificator.toLowerCase && query.identificator.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                query.mobileNumber.number && query.mobileNumber.number.toLowerCase && query.mobileNumber.number.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                query.email && query.email.toLowerCase && query.email.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                query.customerType && query.customerType.toLowerCase && query.customerType.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                query.legalName && query.legalName.toLowerCase && query.legalName.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            //}
        })
        if(searchWord === ""){
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    }

    const clearInput = () => {
        setFilteredData([]);
        setSearchTerm("");
    }
  return (
    <div className="align-center w-100 headerSearch">
        <div className='searchBox ps-3 pe-3'>
            {filteredData.length === 0 ? <i className="icon-search me-3"></i> : <i onClick={clearInput} className="icon-close text-white cursor-pointer me-3"></i>}
            <input type="text" className="form-control search-field bg-white" value={searchTerm} name="SearchTerm" onChange={handleFilter} placeholder="Search for people, ID number, Phone and more..."/>
        </div>
        {/* <div className="form-check form-switch align-center ps-5 pe-5 pt-3">
            <input className="form-check-input" type="checkbox" name="SearchVerified" value="true" role="switch" id="SearchVerified"/>
            <label className="form-check-label" for="SearchVerified">Verified</label>
        </div> */}
        {filteredData.length !== 0 && (
        <div className="dataResult">
            <div className="unverfied-customers-list">
                <div className="unverfied-customers-list-header d-flex justify-content-between align-center ps-4 pe-4 mb-4">
                    <span className="col-3 text-white text-uppercase">Name</span>
                    <span className="col-3 text-white text-uppercase">Email</span>
                    <span className="col-2 text-white text-uppercase">Phone number</span>
                    <span className="col-2 text-white text-uppercase">Customer type</span>
                    <span className="col-2 text-white text-uppercase">Multiverse ID</span>
                </div>
                <hr className='' />
                <div className='unverfied-customers-list-search'>
                    {
                    filteredData.map((customer, key) => {
                        return (
                            <a key={key} className="text-decoration-none customers-hover" href={customer.customerType === 'Individual' ? '/customers/'+customer.id : '/organizations/'+customer.id}>
                                <li className="search-customers-list-item d-flex justify-content-between align-center mb-3">
                                    <div className="customer-info d-flex justify-content-start align-center col-3">
                                        {/* <img src="images/avatar.png" className="rounded-circle" alt="Lisha Cook" /> */}
                                        <div className="customer-name">
                                            <h6 className='text-white'>{customer.fullName}</h6>
                                            <span className="cusomer-status text-white">Online</span>
                                        </div>
                                    </div>
                                    <div className="text-white cusomer-phone-number col-3">
                                        {customer.email}
                                    </div>
                                    <div className="text-white cusomer-phone-number col-2">
                                        <span className="country-code text-white">+995</span> {customer.mobileNumber.countryCode + customer.mobileNumber.number }
                                    </div>
                                    <div className="cusomer-phone-number col-2 text-white">
                                        {customer.customerType}
                                    </div>
                                    <div className="cusomer-phone-number col-2 text-white">
                                        {customer.identificator}
                                    </div>
                                </li>
                            </a>
                        )
                    })
                    }
                </div>
                <hr className='' />
            </div>
        </div>
        )}

    </div>
  )
}