import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Status401 from '../../helpers/helper';
export const OrganizationsList = () => {
    let history = useHistory();
    const [organizations, setOrganization] = useState([]);

    useEffect(() =>{
        async function getOrganzations(){
            try{
            const getOrganizations = await axios.get('http://testadmin.multiverse.ge/api/customer/v1/search-customers', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('userToken')
                }
            });
            setOrganization(getOrganizations.data.data.records);
            } catch(error){
                if (error.response) {
                    Status401(error.response, history);
                }
            }
        }
        getOrganzations();
    },[]);


    return (
        organizations.map((organization, key) => {
            if(organization.customerType === "LegalEntity"){
                return (
                    <a key={key} className="text-decoration-none customers-hover" href={'/organizations/'+organization.id}>
                        <li className="unverfied-customers-list-item d-flex justify-content-between align-items-center align-center">
                            <div className="customer-info d-flex justify-content-start align-center align-items-center col-6">
                                <div className="avatar-text bg-primary">
                                    <span className="text-uppercase">{organization.fullName.charAt(0)}</span>
                                </div>
                                <div className="customer-name">
                                    <h6>{organization.fullName}</h6>
                                </div>
                            </div>
                            <div className="cusomer-phone-number col-3">
                            {organization.identificator}
                            </div>
                            <div className="cusomer-phone-number col-3">
                            +{organization.mobileNumber.countryCode + " " + organization.mobileNumber.number }
                            </div>
                        </li>
                    </a>
                )
            }
        })
    )
} 
