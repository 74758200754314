import React from 'react';
import { Sidebar } from '../../components/sidebar';
import { SidebarRight } from '../../components/sidebarRight';
import { Header } from '../../components/header';
import { CustomerItem } from './customerItem'
import { Link } from 'react-router-dom';
import "./css/customers.css";

//import { useParams } from "react-router-dom";
function Customers() {
    
    return (
        <div>
            <Header/>
            <div className="container-fluid pt-5 pb-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-xl-6 col-xxl-7 main">
                        <div className="content-header">
                            <h1>Customers</h1>
                            <div className="d-flex justify-content-between align-center">
                                <span>Whole data about your business here</span>
                                <div className="options-group">
                                    <Link to="/customers/create"><span className='addCustomer'><i className="icon-user-plus"></i> Add user</span></Link>
                                    {/* <a>Filter</a> */}
                                </div>

                            </div>
                        </div>
                        <div className="main-content">
                            <div className="right-block">
                                <div className="unverfied-customers-list">
                                    <div className="unverfied-customers-list-header d-flex justify-content-between align-center">
                                        <h4 className="col-3">Customers</h4>
                                        <span className="col-3">Email</span>
                                        <span className="col-2">Phone number</span>
                                        <span className="col-2">Customer Type</span>
                                        <span className="col-2">Multiverse ID</span>
                                    </div>
                                    <ul className="unverfied-customers-list-items">
                                        <CustomerItem />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SidebarRight />
                </div>
            </div>
        </div>

    );
}

export default Customers;